<template>
  <div class="bottom-navbar">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="['tab', { active: activeTab === index }]"
      @click="setActiveTab(index)"
    >
      <span class="icon">
        <img :src="activeTab === index ? tab.activeIcon : tab.inactiveIcon" :class="index === 2 ? 'qr-button' : ''">
      </span>
      <span v-if="index != 2" :class="['tab-label', { active: activeTab === index }]">{{ tab.label }}</span>
    </div>
    <div
      class="tab-indicator"
      :style="{ transform: `translateX(${activeTab * 100}%)`, display: `${activeTab != 2 ? 'block':'none'}`}"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import mainStore from '@/store/index';

const tabs = [
  { 
    label: 'Home', 
    activeIcon: require('@/assets/icons/ic-home-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-home.svg')
  },
  { 
    label: 'Transactions', 
    activeIcon: require('@/assets/icons/ic-transactions-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-transactions.svg')
  },
  { 
    label: 'Scan QR', 
    activeIcon: require('@/assets/icons/ic-qr.svg'), 
    inactiveIcon: require('@/assets/icons/ic-qr.svg')
  },
  { 
    label: 'My Cards', 
    activeIcon: require('@/assets/icons/ic-my-cards-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-my-cards.svg')
  },
  { 
    label: 'Profile', 
    activeIcon: require('@/assets/icons/ic-profile-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-profile.svg')
  },
];

const activeTab = ref(0);

const setActiveTab = (index: number) => {
  activeTab.value = index;
  mainStore.setters.setActiveTabIndex(index);
  console.log('setting active tab');
};
 
</script>  
<style>
  .bottom-navbar {
    position: fixed;
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #f8f8f8;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ddd;
  }
  
  .tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    transition: background-color 0.3s;
    position: relative;
  }
  
  .tab.active {
    color: white;
  }
  
  .tab.active .icon {
    color: white;
  }
  
  .tab-label {
    display: block;
    font-size: 12px;
    color: var(--text-color);
  }
  
  .tab-label.active{
    color: var(--primary-color);
    opacity: 1;
  }

  .icon {
    font-size: 1.5em;
    display: block;
  }
  
  .active-icon {
    width: 24px;
    height: 24px;
  }
  
  .tab-indicator {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 20%; /* Each tab takes up 25% of the space */
    height: 2px;
    background-color: var(--primary-color);
    transition: transform 0.2s ease; /* Animate the position change */
  }
  
  .icon img{
    align-content: center;
    margin: 0;
    width: 20px;
    height: 20px;
  }
  
  .icon .qr-button{
    width: 50px;
    height: 46px;
  }

  .badge {
    position: absolute;
    top: 5px;
    min-width: 8px;
    inset-inline-end: 20px;
    background-color: var(--primary-color);
    color: white;
    border: 2px solid white;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 0.75em;
    font-weight: bold;
  }
</style>
  