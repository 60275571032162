import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bottom-navbar" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["src"]

import { ref, watch } from 'vue';
import mainStore from '@/store/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'BottomNavbar',
  setup(__props) {

const tabs = [
  { 
    label: 'Home', 
    activeIcon: require('@/assets/icons/ic-home-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-home.svg')
  },
  { 
    label: 'Transactions', 
    activeIcon: require('@/assets/icons/ic-transactions-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-transactions.svg')
  },
  { 
    label: 'Scan QR', 
    activeIcon: require('@/assets/icons/ic-qr.svg'), 
    inactiveIcon: require('@/assets/icons/ic-qr.svg')
  },
  { 
    label: 'My Cards', 
    activeIcon: require('@/assets/icons/ic-my-cards-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-my-cards.svg')
  },
  { 
    label: 'Profile', 
    activeIcon: require('@/assets/icons/ic-profile-active.svg'), 
    inactiveIcon: require('@/assets/icons/ic-profile.svg')
  },
];

const activeTab = ref(0);

const setActiveTab = (index: number) => {
  activeTab.value = index;
  mainStore.setters.setActiveTabIndex(index);
  console.log('setting active tab');
};
 

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabs, (tab, index) => {
      return _createElementVNode("div", {
        key: index,
        class: _normalizeClass(['tab', { active: activeTab.value === index }]),
        onClick: ($event: any) => (setActiveTab(index))
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("img", {
            src: activeTab.value === index ? tab.activeIcon : tab.inactiveIcon,
            class: _normalizeClass(index === 2 ? 'qr-button' : '')
          }, null, 10, _hoisted_4)
        ]),
        (index != 2)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(['tab-label', { active: activeTab.value === index }])
            }, _toDisplayString(tab.label), 3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2)
    }), 64)),
    _createElementVNode("div", {
      class: "tab-indicator",
      style: _normalizeStyle({ transform: `translateX(${activeTab.value * 100}%)`, display: `${activeTab.value != 2 ? 'block':'none'}`})
    }, null, 4)
  ]))
}
}

})