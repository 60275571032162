<template>
    <div class="container" style="align-content: center;">
        <h1 class="title">Welcome to <br />Tourist Pay ✨</h1>
        <p class="description">Your account is all set and ready!</p>
        <img src="@/assets/imgs/logo.png" alt="Welcome Image" class="welcome-image">
        <router-link to="/home" class="primary-btn clickable">Get Started</router-link>
    </div>
</template>

<script lang="ts" setup>
</script>

<style>
header {
    display: flex;
    gap: 4px;
}

header img {
    width: 48px;
    height: 48px;
}

.app-name{
    font-size: 28px;
    margin-top: 8px;
}

.welcome-image {
    width: 100%; /* Scale image to fit */
    margin: 20px 0;
}

.title {
    font-size: 34px;
    margin: 20px 0;
}

.description {
    font-size: 18px;
    margin: 10px 0;
    color: var(--text-light-color);
    opacity: 0.7;
}

</style>
