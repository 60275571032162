import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import GuideView from '../views/GuideView.vue'
import ChooseAccountView from '@/views/ChooseAccountView.vue'
import WelcomeView from '@/views/WelcomeView.vue'
import HomeView from '@/views/MainView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'guide',
    component: GuideView
  },
  {
    path: '/chooseAccount',
    name: 'chooseAccount',
    component: ChooseAccountView
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
