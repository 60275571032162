import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/ic-back-arrow.svg'
import _imports_1 from '@/assets/imgs/ill-choose-account.svg'


const _hoisted_1 = { class: "container" }

import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseAccountView',
  setup(__props) {

const router = useRouter();

function handleChooseAccount() {
    router.push({ name: 'welcome' });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_0,
      class: "back-btn"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "title" }, "Identity Verification"),
      _createElementVNode("p", { class: "description" }, "In order to create your account, you need to select an account and you will upload the passport document for face verification.")
    ], -1)),
    _cache[3] || (_cache[3] = _createElementVNode("img", {
      src: _imports_1,
      style: {"margin":"42px 0"},
      alt: "Welcome Image",
      class: "welcome-image"
    }, null, -1)),
    _createElementVNode("button", {
      style: {"margin-top":"94px"},
      class: "primary-btn clickable",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (handleChooseAccount()))
    }, "Choose Account")
  ]))
}
}

})