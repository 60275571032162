import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/qi-style.css'
import './qiservices/qi-mobile-v1.js'

// window.qi.debugEnabled = true
// window.qi.debugBaseURL = "http://192.168.31.118:25565";

createApp(App).use(router).mount('#app')
