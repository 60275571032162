import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

import BottomNavbar from '@/components/BottomNavbar.vue';

import mainStore from '@/store/index';
import { MainNavTabType } from '@/models/EnumsTypes';

import { watch, ref } from 'vue';
import InProgress from './InProgress.vue';
import HomeView from './HomeView.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props) {


const currentTab = ref(mainStore.state.activeTab);

watch(() => mainStore.state.activeTab, (newTab) => {
    console.log('setting current tab');
    currentTab.value = newTab;
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (currentTab.value == _unref(MainNavTabType).HOME)
      ? (_openBlock(), _createBlock(HomeView, { key: 0 }))
      : _createCommentVNode("", true),
    (currentTab.value == _unref(MainNavTabType).TRANSACTIONS)
      ? (_openBlock(), _createBlock(InProgress, { key: 1 }))
      : _createCommentVNode("", true),
    (currentTab.value == _unref(MainNavTabType).MY_CARDS)
      ? (_openBlock(), _createBlock(InProgress, { key: 2 }))
      : _createCommentVNode("", true),
    (currentTab.value == _unref(MainNavTabType).PROFILE)
      ? (_openBlock(), _createBlock(InProgress, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(BottomNavbar)
  ]))
}
}

})