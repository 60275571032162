<template>
    <div class="home">
        <HomeView v-if="currentTab == MainNavTabType.HOME"/>
        <InProgress v-if="currentTab == MainNavTabType.TRANSACTIONS"/>
        <InProgress v-if="currentTab == MainNavTabType.MY_CARDS"/>
        <InProgress v-if="currentTab == MainNavTabType.PROFILE"/>
        <BottomNavbar />
    </div>
</template>
  
<script setup lang="ts">

import BottomNavbar from '@/components/BottomNavbar.vue';

import mainStore from '@/store/index';
import { MainNavTabType } from '@/models/EnumsTypes';

import { watch, ref } from 'vue';
import InProgress from './InProgress.vue';
import HomeView from './HomeView.vue';

const currentTab = ref(mainStore.state.activeTab);

watch(() => mainStore.state.activeTab, (newTab) => {
    console.log('setting current tab');
    currentTab.value = newTab;
});


</script>

<style>
.container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}
.no-scroll {
    overflow: hidden;
}
</style>