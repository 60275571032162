<template>
  <router-view/>
</template>

<style>

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden; /* Prevents horizontal scrolling */
}
.back-btn{
  width: 24px;
  height: 24px;
  align-self: flex-start;
}
</style>
