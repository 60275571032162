import { reactive, readonly } from 'vue';
import { MainNavTabType } from '@/models/EnumsTypes';


const state = reactive({
    displayHome: true as boolean,
    activeTab: MainNavTabType.HOME as MainNavTabType
});

const getters = {
    isDisplayHome(): boolean{
        return state.displayHome;
    },
    getActiveTab(): MainNavTabType{
        return state.activeTab;
    }
};

const setters = {
    setDisplayHome(newState: boolean): void{
        state.displayHome = newState;
    },
    setActiveTab(tab: MainNavTabType): void{
        state.activeTab = tab;
    },
    setActiveTabIndex(index: number): void{
        switch(index){
            case 0: state.activeTab = MainNavTabType.HOME; break;
            case 1: state.activeTab = MainNavTabType.TRANSACTIONS; break;
            case 2: state.activeTab = MainNavTabType.SCAN_QR; break;
            case 3: state.activeTab = MainNavTabType.MY_CARDS; break;
            case 4: state.activeTab = MainNavTabType.PROFILE; break;
        }
    }
};

const methods = {
   
};


export default {
    state: readonly(state),
    getters,
    setters,
    methods,
};