<template>
    <div class="container main-header">
        <header style="justify-content: space-between;">
            <div style="display: flex;">
                <img src="@/assets/imgs/logo.png" alt="App Logo" class="logo">
                <h2 class="app-name" style="color: var(--white-color)">Tourist Pay</h2>
            </div>
            <img class="notifications" src="@/assets/icons/ic-notifications.svg" alt="">
        </header>
        <p class="description">Balance</p>
        <h2 style="color: var(--white-color)">₺ 394,31</h2>
        <div class="icon-container">
            <div class="icon-item">
                <img src="@/assets/icons/ic-topup.svg" alt="Icon 1">
                <p style="color: #1355FF">Top Up</p>
            </div>
            <div class="icon-item">
                <img src="@/assets/icons/ic-send.svg" alt="Icon 2">
                <p style="color: #E46962">Send</p>
            </div>
            <div class="icon-item">
                <img src="@/assets/icons/ic-withdraw.svg" alt="Icon 3">
                <p style="color: #12A58C">Withdraw</p>
            </div>
            <div class="icon-item">
                <img src="@/assets/icons/ic-more.svg" alt="Icon 4">
                <p style="color: #6750A4">More</p>
            </div>
        </div>        
    </div>
    <div class="transactions-section" style="margin-top: 60px; padding: 24px;">
        <h3 class="transactions-title" style="color: var(--text-color)">Transactions</h3>
        <div class="no-transactions" style="text-align: center">
            <img style="width:240px; height: 240px;" src="@/assets/imgs/ill-no-trans.svg" alt="No Transactions Illustration" class="no-transactions-illustration">
            <p class="no-transactions-message" style="color: var(--text-light-color); text-align: center; margin-top: 24px;">No transactions yet.</p>
        </div>
    </div>

</template>

<script setup lang="ts">
</script>

<style scoped>
.main-header {
    margin-top: 0px;
    background: url('@/assets/imgs/ic-main-background.svg') no-repeat center center;
    background-size: cover;
    position: relative; /* Make this relative for the absolute child positioning */
    padding-bottom: 60px; /* Add padding for spacing */
}

.main-header .description {
    color: var(--white-color);
    margin-top: 20px;
    margin-bottom: 12px;
    font-weight: bold;
}

.notifications {
    width: 32px;
    height: 32px;
}

/* Styles for the new icon container */
.icon-container {
    position: absolute;
    width: 100%;
    padding: 0 24px;
    bottom: -70px; /* Position the container just a bit below the main header */
    left: 50%;
    transform: translateX(-50%); /* Center the container horizontally */
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

/* Each icon-item */
.icon-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Set a width for the container */
    background-color: var(--white-color);
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 24px 0px;
}

.icon-item img {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
}

.icon-item p {
    margin: 0;
    font-size: 14px;
    margin-top: 8px;
    color: var(--text-dark-color);
}
</style>
