<template>
    <div class="container">
        <img src="@/assets/icons/ic-back-arrow.svg" class="back-btn">
        <div>
            <h1 class="title">Identity Verification</h1>
            <p class="description">In order to create your account, you need to select an account and you will upload the passport document for face verification.</p>
        </div>
        <img src="@/assets/imgs/ill-choose-account.svg" style="margin: 42px 0" alt="Welcome Image" class="welcome-image">
        <button style="margin-top: 94px;" class="primary-btn clickable" @click="handleChooseAccount()">Choose Account</button>
    </div>
</template>


<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter();

function handleChooseAccount() {
    router.push({ name: 'welcome' });
}
</script>